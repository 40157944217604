import "./styles.css";
import React, { useState } from "react";
import { PDFTemplate } from "./components/template2";
import { PDFViewer } from "@react-pdf/renderer";

export default function App() {
  const [orders, setOrders] = useState(null);

  const handleChange = async (e) => {
    const file = e.target?.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = (e) => {
        const jsonObj = JSON.parse(e.target?.result);
        transformSheetData(jsonObj);
      };
    }
  };

  const transformSheetData = (data) => {
    const orderMap = data.reduce((acc, curr, ind) => {
      acc[curr.order_number] = {
        orderNumber: curr.order_number,
        orderDate: curr.order_date,
        customerNote: curr.plain_orders__vl_order_comments,
        customerName: curr.billing_full_name,
        shippingMethod: curr.shipping_method_title,
        paymentMethod: curr.payment_method_title,
        items: curr.products.map((p) => ({
          quantity: p.qty,
          itemName: p.product_name_main,
          productVariations: p.product_variation,
          productCategory: p.primary_category,
        })),
      };
      return acc;
    }, {});

    Object.values(orderMap).forEach((o) => {
      o.items.sort((a, b) => {
        const nameA = a.itemName.toUpperCase();
        const variationA = a.productVariations
          ? a.productVariations.toUpperCase()
          : "";
        const nameB = b.itemName.toUpperCase();
        const variationB = b.productVariations
          ? b.productVariations.toUpperCase()
          : "";
        if (variationA < variationB) {
          return -1;
        }
        if (variationA > variationB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    });

    // add grouped items to the order
    Object.values(orderMap).forEach((order) => {
      order.productsByCategory = order.items.reduce((acc, i) => {
        switch (true) {
          case ["szójaviasz gyertya", "szójagyertya", "gyertya"].some((s) =>
            i.itemName.includes(s)
          ):
            upsertCategory(acc, i, "Szójaviasz gyertya");
            break;
          case i.productVariations === "Illatviasz méret: Minta":
            upsertCategory(acc, i, "Illatviasz minta");
            break;
          case i.productVariations === "Illatviasz méret: Teljes kiszerelés":
            upsertCategory(acc, i, "Illatviasz teljes kiszerelés");
            break;
          case ["könyvjelző", "könyvjelzők"].some((s) =>
            i.itemName.includes(s)
          ):
            upsertCategory(acc, i, "Könyvjelző");
            break;
          default:
            upsertCategory(acc, i, "Kiegészítő termék");
            break;
        }
        return acc;
      }, {});
    });

    setOrders(Object.values(orderMap));
  };

  const upsertCategory = (acc, item, category) => {
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
  };

  return (
    <div className="App">
      <h1>Világok Lángja packing list generator 📦🔥</h1>
      <h3>Import a JSON file to generate packing lists:</h3>
      <div>
        <input accept="application/json" type="file" onChange={handleChange} />
      </div>
      <div>
        {orders && (
          <>
            <PDFViewer width={600} height={900}>
              <PDFTemplate orders={orders} />
            </PDFViewer>
          </>
        )}
      </div>
    </div>
  );
}
