import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { vlLogoBW } from "./image";

Font.register({
  family: "Montserrat",
  src: "/fonts/functionpro-book-webfont.ttf",
});

Font.register({
  family: "playfair",
  src: "/fonts/playfair-display-bold.ttf",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  body: {
    backgroundColor: "white",
    paddingBottom: 30,
    paddingTop: 15,
    paddingHorizontal: 20,
  },
  // Header
  flexWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  halfWrapper: {
    width: "50%",
  },
  orderNumberWrapper: {
    width: "33%",
    textAlign: "right",
    fontSize: 14,
    fontFamily: "Montserrat",
  },

  // Customer data
  title: {
    marginVertical: 16,
    fontSize: 14,
    fontFamily: "playfair",
  },
  subtitle: {
    fontSize: 11,
    margin: 12,
    fontFamily: "playfair",
  },
  customerInfo: {
    marginBottom: 6,
  },
  orderKey: {
    fontSize: 9,
    fontFamily: "playfair",
  },
  orderValue: {
    fontSize: 10,
    fontFamily: "Montserrat",
    marginBottom: 5,
  },

  // Order table
  productHeader: {
    width: "85%",
    padding: 5,
    border: 0,
    fontSize: 14,
  },
  quantityHeader: {
    width: "15%",
    textAlign: "center",
    padding: 5,
    border: 0,
    fontSize: 9,
  },
  priceHeader: {
    width: "15%",
    textAlign: "right",
    padding: 5,
    border: 0,
  },
  productCell: {
    width: "85%",
    flexGrow: 0,
    padding: 5,
  },
  quantityCell: {
    width: "15%",
    textAlign: "center",
    padding: 5,
  },
  priceCell: {
    width: "15%",
    textAlign: "right",
    padding: 5,
  },
  productName: {
    fontSize: 9,
    fontFamily: "Montserrat",
  },
  thText: {
    fontFamily: "playfair",
  },
  tdText: {
    fontSize: 8,
    fontFamily: "Montserrat",
  },
  th: {
    width: "50%",
    textAlign: "center",
    fontSize: 10,
    fontFamily: "playfair",
    padding: 5,
  },
  tr: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderColor: "black",
    borderTop: 1,
    borderStyle: "solid",
    //flexWrap: "nowrap",
    //justifyContent: "space-between",
  },

  text: {
    margin: 10,
    fontSize: 10,
    fontFamily: "Montserrat",
  },

  // Page Numbers
  pageNumber: {
    position: "absolute",
    fontSize: 9,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#404040",
    fontFamily: "Montserrat",
  },
});

// Create Document Component
export const PDFTemplate = ({ orders }) => (
  <Document>
    {orders.map((order) => (
      <Page key={order.orderNumber} size="A5" style={styles.body}>
        {/* Header */}
        <View style={styles.flexWrapper}>
          <View style={{ width: "33%" }}>
            <Text style={[styles.title]}>Csomagolási lista</Text>
          </View>
          <View
            style={{
              width: "33%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Image src={vlLogoBW} style={{ width: "36px", height: "25px" }} />
          </View>
          <View style={styles.orderNumberWrapper}>
            <Text>#{order.orderNumber}</Text>
          </View>
        </View>

        {/* Title
        <View style={styles.flexWrapper}>
          <View style={{ width: "40%" }}></View>
          <View style={{ width: "60%" }}>
            <Text style={[styles.subtitle, { textAlign: "right" }]}>
              <Text style={[styles.subtitle, { textAlign: "right" }]}>
                Összekészítette | Csomagolta
              </Text>
            </Text>
          </View>
        </View> */}

        {/* Customer data */}
        <View style={styles.customerInfo}>
          <View style={styles.flexWrapper}>
            <View style={styles.halfWrapper}>
              <Text style={styles.orderKey}>Vásárló:</Text>
              <Text style={styles.orderValue}>{order.customerName}</Text>
            </View>
            <View style={styles.halfWrapper}>
              <Text style={[styles.subtitle, { textAlign: "right" }]}>
                Összekészítette | Csomagolta
              </Text>
            </View>
          </View>
          <Text style={styles.orderKey}>Dátum:</Text>
          <Text style={styles.orderValue}>
            {order.orderDate.toLocaleString("hu-HU")}
          </Text>
          <Text style={styles.orderKey}>Fizetési mód:</Text>
          <Text style={styles.orderValue}>{order.paymentMethod}</Text>
          <View style={styles.flexWrapper}>
            <View style={styles.halfWrapper}>
              <Text style={styles.orderKey}>Szállítási mód:</Text>
              <Text style={styles.orderValue}>{order.shippingMethod}</Text>
            </View>
            <View style={styles.halfWrapper}>
              <Text style={[styles.subtitle, { textAlign: "right" }]}>
                Köszönjük a vásárlást! :)
              </Text>
            </View>
          </View>
          {order.customerNote && (
            <>
              <Text style={styles.orderKey}>Megjegyzés:</Text>
              <Text style={styles.orderValue}>{order.customerNote}</Text>
            </>
          )}
        </View>

        <Text
          style={[styles.orderKey, { textAlign: "center", marginBottom: 5 }]}
        >
          A számlát e-mailben elküldtük Neked!
        </Text>

        {/* Order table */}
        {Object.entries(order.productsByCategory).map(([category, items]) => (
          <>
            <View
              key={category}
              style={[styles.flexWrapper, { alignItems: "flex-end" }]}
            >
              <View style={styles.productHeader}>
                <Text style={styles.thText}>{category}</Text>
              </View>
              <View style={styles.quantityHeader}>
                <Text style={styles.thText}>Darab</Text>
              </View>
            </View>
            {items.map((item) => (
              <View key={item.itemName} wrap={false} style={styles.tr}>
                <View style={styles.productCell}>
                  <Text style={styles.productName}>{item.itemName}</Text>
                  <Text style={styles.tdText}>{item.productVariations}</Text>
                </View>
                <View style={styles.quantityCell}>
                  <Text style={styles.tdText}>{item.quantity}</Text>
                </View>
              </View>
            ))}
          </>
        ))}
        <Text
          style={styles.pageNumber}
          render={({ subPageNumber, subPageTotalPages }) =>
            `#${order.orderNumber} • ${subPageNumber} / ${subPageTotalPages}`
          }
          fixed
        />
      </Page>
    ))}
  </Document>
);
